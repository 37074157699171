/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Lottie from "lottie-react";
import groovyWalkAnimation2 from "../images/stars.json"; 
import groovyWalkAnimation3 from "../images/stars2.json"; 
import Header from "./header"
import "./layout.css"
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const BGAnimation = () => isBrowser ?
  <Lottie animationData={groovyWalkAnimation2} loop={true}/>:
  <Lottie animationData={groovyWalkAnimation3} loop={true}/>

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <>
      <Header siteTitle={"SlackOut Panel for Premiere Pro"} />
      <div className="backgroundAnimation">
           <BGAnimation></BGAnimation> 
      </div>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
          zIndex:2000,
          position: 'relative'
        }}
      >
        <main>{children}</main>
        <footer
          style={{
            marginTop: `2rem`,
          }}
        >
          © {new Date().getFullYear()}, Built by
          {` `}
          <a href="https://www.picaro.video">Picaro</a>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
